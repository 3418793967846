<template>
    <a-layout-sider v-model:collapsed="sideMenuCollapsed">
        <div class="logo" :style="{ width: sideMenuCollapsed ? '80px' : '200px' }">
            <img
                src="../../../assets/logo-light.png"
                :width="sideMenuCollapsed ? 50 : 115"
                :height="sideMenuCollapsed ? 10 : 15"
            />
        </div>
        <a-menu
            v-model:selectedKeys="selectedKeys"
            v-model:openKeys="openKeys"
            theme="dark"
            mode="inline"
        >
            <template v-for="(group, groupIndex) in routes[currentModule]" :key="group.groupName">
                <a-sub-menu>
                    <template #title>
                        <span>
                            <!-- <component :is="group.icon"></component> -->
                            <div
                                :class="sideMenuCollapsed ? 'collapsed' : ''"
                                style="padding-left: 10px"
                            >
                                {{ group.title }}
                            </div>
                        </span>
                    </template>
                    <a-menu-item
                        v-for="route in group?.children"
                        :key="route.path"
                        @click="routerPush(route, group.groupName)"
                    >
                        <span>{{ route.meta.title }}</span>
                    </a-menu-item>
                </a-sub-menu>
            </template>
        </a-menu>
    </a-layout-sider>
</template>

<script>
import {
    HomeOutlined,
    PieChartOutlined,
    PartitionOutlined,
    RiseOutlined,
    SolutionOutlined,
    LockOutlined,
    HistoryOutlined,
    FileDoneOutlined,
    DownloadOutlined,
    ImportOutlined,
    GoldOutlined,
    VerifiedOutlined,
} from "@ant-design/icons-vue";
import { defineComponent, computed, reactive, toRefs, watch, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { apiSaveAccessPage } from "@/api";

export default defineComponent({
    components: {
        HomeOutlined,
        PieChartOutlined,
        PartitionOutlined,
        RiseOutlined,
        SolutionOutlined,
        LockOutlined,
        HistoryOutlined,
        FileDoneOutlined,
        DownloadOutlined,
        ImportOutlined,
        GoldOutlined,
        VerifiedOutlined,
    },
    setup() {
        const { getters, commit } = useStore();
        const { push } = useRouter();
        const state = reactive({
            sideMenuCollapsed: computed(() => getters["app/sider"]?.collapsed),
            selectedKeys: [getters["app/sider"]?.currentPath],
            openKeys: [getters["app/sider"]?.groupName],
            currentModule: computed(() => getters["app/currentModule"]),
            breadcrumb: computed(() => getters["app/breadcrumb"]),
            routes: getters["app/sider"]?.menus,
        });

        const saveAccessPage = async pageName => {
            await apiSaveAccessPage(pageName);
        };

        const routerPush = (route, groupName) => {
            push(route?.path);
            saveAccessPage(route?.meta?.title);
            commit("app/setSider", { groupName, currentPath: route?.path });
            if (state.breadcrumb.find(item => item.path == route.path)) {
                return;
            } else {
                const newBreadcrumb = [
                    ...state.breadcrumb,
                    {
                        ...route,
                        groupName,
                    },
                ];
                commit("app/setBreadcrumb", newBreadcrumb);
            }
        };

        watch(
            [() => getters["app/sider"]?.currentPath, () => getters["app/sider"]?.groupName],
            ([newPath, newName]) => {
                state.selectedKeys = [newPath];
                state.openKeys = [newName];
            }
        );

        return {
            ...toRefs(state),
            routerPush,
        };
    },
});
</script>

<style lang="less" scoped>
.logo {
    text-align: center;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
        transition: all 0.3s ease;
    }
}
.collapsed {
    width: 50px;
    padding-left: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
</style>
