<template>
    <a-modal
        v-model:visible="visible"
        title="详情"
        @cancel="handleCancel"
        :footer="null"
        width="800px"
        :destroyOnClose="true"
    >
        <div class="content">
            <a-descriptions title="" :column="2">
                <a-descriptions-item label="条码" :span="2">
                    {{ supplierCodeRuleInfo?.itemBarcode }}
                </a-descriptions-item>
                <a-descriptions-item label="存货名称">
                    {{ supplierCodeRuleInfo?.itemName }}
                </a-descriptions-item>
                <a-descriptions-item label="存货编码">
                    {{ supplierCodeRuleInfo?.itemCode }}
                </a-descriptions-item>
                <a-descriptions-item label="供应商">
                    {{ supplierCodeRuleInfo?.supplier }}
                </a-descriptions-item>
            </a-descriptions>
        </div>
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleCancel">关闭</a-button>
        </a-space>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiSearchDetailCode, apiMaterialDetail } from "@/api";
export default defineComponent({
    props: ["supplierCodeRuleInfo"],
    emits: ["update:supplierCodeRuleInfo"],

    setup(props, { emit }) {
        const state = reactive({
            visible: false,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:supplierCodeRuleInfo", null);
        };

        watch(
            () => props.supplierCodeRuleInfo,
            newVal => {
                state.visible = Boolean(newVal);
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    .text {
        font-size: 16px;
        margin-left: 20px;
        &.searching {
            color: #2bce1d;
        }
    }
}
.serach-space {
    display: flex;
    justify-content: flex-end;
}
</style>
