<template>
    <a-modal
        v-model:visible="visible"
        title="设置新密码"
        @cancel="handleCancel"
        width="600px"
        :footer="null"
    >
        <a-form
            :model="formData"
            name="basic"
            :label-col="{ span: 5 }"
            :wrapper-col="{ span: 18 }"
            autocomplete="off"
            @finish="onFinish"
        >
            <a-form-item
                label="旧密码"
                name="oldPassword"
                :rules="[{ required: true, message: '请输入旧密码' }, { validator: checkPwd }]"
            >
                <a-input-password v-model:value="formData.oldPassword" :maxlength="20" showCount />
            </a-form-item>

            <a-form-item
                label="新密码"
                name="newPassword"
                :rules="[{ required: true, message: '请输入新密码' }, { validator: checkPwd }]"
            >
                <a-input-password v-model:value="formData.newPassword" :maxlength="20" showCount />
            </a-form-item>

            <a-form-item
                label="确认密码"
                name="confirmNewPassword"
                :rules="[{ required: true, message: '请再次确认密码' }, { validator: checkPwd }]"
            >
                <a-input-password
                    v-model:value="formData.confirmNewPassword"
                    :maxlength="20"
                    showCount
                />
            </a-form-item>

            <a-form-item :wrapper-col="{ offset: 17, span: 6 }">
                <a-button style="margin-right: 8px" @click="handleCancel">取消</a-button>
                <a-button type="primary" html-type="submit">提交</a-button>
            </a-form-item>
        </a-form>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiResetPwd, apiGetPwdPattern, apiModifyPwd } from "@/api";
import { showMessage, checkValueIsAvailable, toType } from "@/utils/common";
export default defineComponent({
    props: ["showResetPwd"],
    emits: ["update:showResetPwd"],
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            formData: {
                oldPassword: "",
                newPassword: "",
                confirmNewPassword: "",
            },
            typeCandidate: [],
            pwdPattern: {
                message: "",
                value: "",
            },
        });

        const getPwdPattern = async () => {
            let res = await apiGetPwdPattern();
            if (res.status === "SUCCESS") {
                state.pwdPattern.message = res.data.value;
                state.pwdPattern.value = new RegExp(res.data.key);
            }
        };

        getPwdPattern();

        const handleCancel = () => {
            emit("update:showResetPwd", false);
        };

        const onFinish = async () => {
            const isRestInitialPwd = toType(props.showResetPwd) === "object";
            let fn = isRestInitialPwd ? apiResetPwd : apiModifyPwd;
            let res = await fn({
                ...(isRestInitialPwd && { username: props.showResetPwd.username }),
                ...state.formData,
            });
            if (res.status === "SUCCESS") {
                showMessage("success", res.msg);
                handleCancel();
                emit("successs", state.formData.newPassword);
            }
        };

        const checkPwd = (rule, value) => {
            if (!state.pwdPattern.value.test(value)) {
                return Promise.reject(state.pwdPattern.message);
            } else {
                return Promise.resolve();
            }
        };

        const checkSpaceAll = (rule, value) => {
            if (checkValueIsAvailable(value, "spaceAll")) {
                return Promise.reject("不能为空或全是空格");
            } else {
                return Promise.resolve();
            }
        };

        watch(
            () => props.showResetPwd,
            newVal => {
                state.visible = Boolean(newVal);
                if (toType(newVal) === "object") {
                    state.formData.oldPassword = newVal.password;
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            onFinish,
            checkPwd,
            checkSpaceAll,
        };
    },
});
</script>
