<template>
    <a-modal
        v-model:visible="visible"
        title="物料详情"
        @cancel="handleCancel"
        :footer="null"
        width="800px"
        :destroyOnClose="true"
    >
        <div class="content">
            <a-descriptions title="" :column="2">
                <a-descriptions-item label="条码" :span="2">
                    {{ detailInfo?.itemBarcode }}
                </a-descriptions-item>
                <a-descriptions-item label="物料名称">
                    {{ detailInfo?.itemName }}
                </a-descriptions-item>
                <a-descriptions-item label="物料编码">
                    {{ detailInfo?.itemCode }}
                </a-descriptions-item>
                <a-descriptions-item label="规格型号">
                    {{ detailInfo?.itemSpec }}
                </a-descriptions-item>
                <a-descriptions-item label="批次号">
                    {{ detailInfo?.batchNo }}
                </a-descriptions-item>
                <a-descriptions-item label="供应商">
                    {{ detailInfo?.supplier }}
                </a-descriptions-item>
                <a-descriptions-item label="采购订单号">
                    {{ detailInfo?.purchaseOrderCode }}
                </a-descriptions-item>
                <a-descriptions-item label="条码录入方式">
                    {{ detailInfo?.barcodeImportWayDesc }}
                </a-descriptions-item>
                <a-descriptions-item label="操作人">
                    {{ detailInfo?.createBy }}
                </a-descriptions-item>
            </a-descriptions>
        </div>
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleCancel">关闭</a-button>
        </a-space>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiSearchDetailCode, apiMaterialDetail } from "@/api";
export default defineComponent({
    props: ["materialCode"],
    emits: ["update:materialCode"],

    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            detailInfo: null,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:materialCode", null);
        };

        const getMaterialDetail = async code => {
            let res = await apiMaterialDetail(code);
            const { status, data } = res;
            if (status === "SUCCESS") {
                if (data) {
                    state.detailInfo = data;
                } else {
                    showMessage("info", "未查询到该条码信息");
                }
            }
        };

        watch(
            () => props.materialCode,
            newVal => {
                state.visible = Boolean(newVal);
                if (newVal) {
                    getMaterialDetail(newVal);
                }
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
        };
    },
});
</script>

<style lang="less" scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    .text {
        font-size: 16px;
        margin-left: 20px;
        &.searching {
            color: #2bce1d;
        }
    }
}
.serach-space {
    display: flex;
    justify-content: flex-end;
}
// :deep(.ant-spin-dot-item ){
//     background-color: #2bce1d;
// }
</style>
