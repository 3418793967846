<template>
    <a-layout style="min-height: 100vh">
        <Sider />
        <a-layout>
            <Header />
            <a-layout-content class="layout-content">
                <a-space class="bread-wrapper" direction="horizontal" :size="8">
                    <div
                        v-if="defalutPage"
                        :class="['item', defalutPage?.path == currentPath ? 'active' : '']"
                        @click="handleChangePage(defalutPage)"
                    >
                        {{ defalutPage?.meta?.title }}
                    </div>
                    <template v-for="(item, index) in breadcrumb" :key="item.path">
                        <div
                            v-if="item.path !== defalutPage?.path"
                            :class="['item', item.path == currentPath ? 'active' : '']"
                            @click="handleChangePage(item)"
                        >
                            {{ item?.meta?.title }}
                            <close-circle-outlined
                                class="close-icon"
                                @click.stop="handleClosePage(index)"
                            />
                        </div>
                    </template>
                </a-space>
                <router-view />
            </a-layout-content>
        </a-layout>
    </a-layout>
</template>

<script>
import { CloseCircleOutlined } from "@ant-design/icons-vue";
import { computed, defineComponent } from "vue";
import Sider from "./components/sider.vue";
import { apiSaveAccessPage } from "@/api";
import Header from "./components/header.vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
export default defineComponent({
    components: {
        CloseCircleOutlined,
        Sider,
        Header,
    },
    setup() {
        const { getters, commit } = useStore();
        const { push } = useRouter();
        const defalutPage = getters["app/menuPermisson"]?.["menu:home"]
            ? {
                  path: "/index-workbench",
                  meta: {
                      title: "首页-工作台",
                  },
                  groupName: "menu:home",
              }
            : null;
        const breadcrumb = computed(() => getters["app/breadcrumb"]);
        const currentPath = computed(() => getters["app/sider"]?.currentPath);

        const saveAccessPage = async pageName => {
            await apiSaveAccessPage(pageName);
        };

        const handleChangePage = route => {
            push(route?.path);
            saveAccessPage(route?.meta?.title);
            commit("app/setSider", { groupName: route?.groupName, currentPath: route?.path });
        };

        const handleClosePage = index => {
            const newBreadcrumb = breadcrumb.value.filter((_, _index) => index != _index);
            commit("app/setBreadcrumb", newBreadcrumb);
        };

        return {
            defalutPage,
            breadcrumb,
            currentPath,
            handleChangePage,
            handleClosePage,
        };
    },
});
</script>

<style lang="less" scoped>
.layout-content {
    margin: 16px;
    //overflow-x: scroll;
    // &::-webkit-scrollbar {
    //     display: none;
    // }
    // & > div:nth-child(3) {
    //     height: calc(100vh - 135px);
    // }
    .bread-wrapper {
        display: flex;
        align-items: flex-start;
        width: 100%;
        height: 52px;
        box-sizing: border-box;
        overflow: hidden;
        &:hover {
            overflow-x: scroll;
            .item {
                &.active {
                    height: 47px;
                }
            }
        }
        .item {
            padding: 0 20px;
            opacity: 0.6;
            line-height: 40px;
            white-space: nowrap;
            background: #fff;
            font-size: 12px;
            text-align: center;
            cursor: pointer;
            position: relative;
            &.active {
                height: 52px;
                color: @primary-color;
                opacity: 1;
            }
            .close-icon {
                position: absolute;
                right: 3px;
                top: 4px;
                cursor: default;
            }
        }
    }
}
</style>
