<template>
    <MaterialDetail v-model:materialCode="materialCode" />
    <SupplierCodeRule v-model:supplierCodeRuleInfo="supplierCodeRuleInfo" />
    <a-modal
        v-model:visible="visible"
        title="扫码查询结果"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
    >
        <div class="content">
            <a-descriptions title="" :column="1">
                <a-descriptions-item label="条码">
                    {{ searchResult?.itemBarcode }}
                </a-descriptions-item>
                <a-descriptions-item label="条码类型">
                    {{ searchResult?.typeZh }}
                </a-descriptions-item>
                <a-descriptions-item label="名称">
                    {{ searchResult?.itemName }}
                </a-descriptions-item>
                <!-- <a-descriptions-item label="规格型号">
                    {{ searchResult?.itemSpec }}
                    供应商编码没有规格型号
                </a-descriptions-item> -->
            </a-descriptions>
        </div>
        <a-space class="serach-space" direction="horizontal" :size="8">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="handleViewDetail">查看详情</a-button>
        </a-space>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiSearchDetailCode, apiMaterialDetail } from "@/api";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import MaterialDetail from "./materialDetail";
import SupplierCodeRule from "./supplierCodeRule";
export default defineComponent({
    props: ["searchResult"],
    emits: ["update:searchResult"],
    components: {
        MaterialDetail,
        SupplierCodeRule,
    },
    setup(props, { emit }) {
        const { push } = useRouter();
        const { commit } = useStore();
        const state = reactive({
            visible: false,
            materialCode: false,
            supplierCodeRuleInfo: null,
        });

        const handleCancel = () => {
            state.visible = false;
            emit("update:searchResult", null);
        };

        const handleViewDetail = () => {
            // type
            // FINISHED_PRODUCTS 车间产成品
            // WORK_IN_PROGRESS 车间在制品
            // SUPPLIER_DATA_IMPORT 条码导入
            // SUPPLIER_RULE_MATCH 供应商规则
            const { type, itemBarcode } = props?.searchResult;
            if (type == "ERP_EV" || type == "SUPPLIER_DATA_IMPORT") {
                state.materialCode = itemBarcode;
                return;
            }
            if (type == "SUPPLIER_RULE_MATCH") {
                state.supplierCodeRuleInfo = props?.searchResult;
                return;
            }
            handleCancel();
            const productTypeUrl = {
                FINISHED_PRODUCTS: "workshop-finished-product-manage",
                WORK_IN_PROGRESS: "workshop-wip-manage",
            };
            const path = productTypeUrl[type];
            push(`${path}?barcode=${itemBarcode}?t=${+new Date().getTime()}`);
            commit("app/setSider", { groupName: "workshop-product-manage", currentPath: path });
        };

        watch(
            () => props.searchResult,
            newVal => {
                state.visible = Boolean(newVal);
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            handleViewDetail,
        };
    },
});
</script>

<style lang="less" scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    .text {
        font-size: 16px;
        margin-left: 20px;
        &.searching {
            color: #2bce1d;
        }
    }
}
.serach-space {
    display: flex;
    justify-content: flex-end;
}
// :deep(.ant-spin-dot-item ){
//     background-color: #2bce1d;
// }
</style>
