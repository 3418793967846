<template>
    <SearchResult v-model:searchResult="searchResult"></SearchResult>
    <a-modal
        v-model:visible="visible"
        title="扫码查询"
        @cancel="handleCancel"
        :footer="null"
        :destroyOnClose="true"
    >
        <div class="content">
            <a-spin />
            <span v-show="!loading" class="text">请扫描条码</span>
            <span v-show="loading" class="text searching">已成功识别条码，正在检索信息</span>
        </div>
        <a-input v-model:value="barcode" />
        <a-space class="cancel-btn-wrapper" direction="horizontal" :size="8">
            <a-button @click="handleCancel">取消</a-button>
            <a-button type="primary" @click="getDetailByCode">确定</a-button>
        </a-space>
    </a-modal>
</template>
<script>
import { defineComponent, watch, reactive, toRefs } from "vue";
import { apiSearchDetailCode } from "@/api";
import { showMessage } from "@/utils/common";
import SearchResult from "./searchResult";
export default defineComponent({
    props: ["codeSearchVisible"],
    emits: ["update:codeSearchVisible"],
    components: {
        SearchResult,
    },
    setup(props, { emit }) {
        const state = reactive({
            visible: false,
            loading: false,
            searchResult: null,
            barcode: "",
        });

        const handleCancel = () => {
            state.visible = false;
            state.barcode = "";
            emit("update:codeSearchVisible", null);
        };

        const getDetailByCode = async () => {
            if (!state.barcode) return showMessage("warning", "请输入条码");
            state.loading = true;
            let res = await apiSearchDetailCode(state.barcode?.trim());
            state.loading = false;
            const { status, data } = res;
            if (status === "SUCCESS") {
                if (data) {
                    state.searchResult = data;
                } else {
                    showMessage("info", "未查询到该条码信息");
                }
            }
        };

        watch(
            () => props.codeSearchVisible,
            newVal => {
                state.visible = Boolean(newVal);
            }
        );

        watch(
            () => state.searchResult,
            newVal => {
                if (!newVal) handleCancel();
            }
        );

        return {
            ...toRefs(state),
            handleCancel,
            getDetailByCode,
        };
    },
});
</script>

<style lang="less" scoped>
.content {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 20px 0;
    .text {
        font-size: 16px;
        margin-left: 20px;
        &.searching {
            color: #2bce1d;
        }
    }
}
.cancel-btn-wrapper {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
}
// :deep(.ant-spin-dot-item ){
//     background-color: #2bce1d;
// }
</style>
