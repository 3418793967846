<template>
    <CodeSearch v-model:codeSearchVisible="codeSearchVisible" />
    <div class="header-container">
        <ResetPwd v-model:showResetPwd="showResetPwd" />
        <div class="header-left">
            <component
                class="collapsed-icon"
                :is="sideMenuCollapsed ? 'menu-unfold-outlined' : 'menu-fold-outlined'"
                @click="changeSideMenuCollapsed"
            />
            <a-menu v-model:selectedKeys="currentModule" mode="horizontal" style="height: 55px">
                <a-menu-item
                    v-for="(item, index) in headerModules.filter(item => menuPermisson[item.code])"
                    :key="index"
                    @click="changeCurrentModule(index)"
                >
                    <span style="position: relative; top: 5px; letter-spacing: 2px">
                        {{ item?.title }}
                    </span>
                </a-menu-item>
            </a-menu>
        </div>
        <div class="header-right">
            <a-space direction="horizontal" :size="30">
                <a-dropdown placement="bottom">
                    <img src="../../../assets/tool.png" width="28" height="28" />
                    <template #overlay>
                        <a-menu style="top: 16px">
                            <a-menu-item
                                @click="() => (codeSearchVisible = true)"
                                key="0"
                                style="textalign: center"
                            >
                                条码查询
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
                <a-dropdown placement="bottom">
                    <line-chart-outlined style="font-size: 17px; cursor: default" />
                    <template #overlay>
                        <a-menu style="top: 16px">
                            <a-menu-item
                                @click="
                                    () => openPanel('/large-screen/integrated-production-panel')
                                "
                                key="0"
                                style="textalign: center"
                            >
                                集成生产综合看板
                            </a-menu-item>
                            <a-menu-item
                                @click="() => openPanel('/large-screen/workshop-production-panel')"
                                key="0"
                                style="textalign: center"
                            >
                                车间生产综合看板
                            </a-menu-item>
                            <a-menu-item
                                @click="() => openPanel('/large-screen/exception-panel')"
                                key="1"
                                style="textalign: center"
                            >
                                异常事件看板
                            </a-menu-item>
                            <a-menu-item
                                @click="() => openPanel('/large-screen/project-progress-panel')"
                                key="1"
                                style="textalign: center"
                            >
                                项目进度看板
                            </a-menu-item>
                        </a-menu>
                    </template>
                </a-dropdown>
                <a-space direction="horizontal" :size="30">
                    <a-tooltip>
                        <template #title>
                            {{ isFullScreen ? "退出全屏" : "全屏" }}
                        </template>
                        <component
                            :is="isFullScreen ? 'compress-outlined' : 'expand-outlined'"
                            @click="fullScreen"
                        />
                    </a-tooltip>
                    <a-tooltip>
                        <template #title>帮助</template>
                        <question-circle-outlined
                            style="font-size: 17px"
                            @click="handlePreviewHelp"
                        />
                    </a-tooltip>

                    <a-dropdown placement="bottom">
                        <span>
                            <user-outlined />
                            <span class="username">{{ userName }}</span>
                        </span>
                        <template #overlay>
                            <a-menu style="top: 16px">
                                <a-menu-item
                                    key="0"
                                    style="textalign: center"
                                    @click="showResetPwd = true"
                                >
                                    修改密码
                                </a-menu-item>
                                <a-menu-item key="1" style="textalign: center" @click="loginOut">
                                    退出登录
                                </a-menu-item>
                            </a-menu>
                        </template>
                    </a-dropdown>
                </a-space>
            </a-space>
        </div>
    </div>
</template>

<script>
import { computed, defineComponent, ref } from "vue";
import screenfull from "screenfull";
import {
    UserOutlined,
    ExpandOutlined,
    CompressOutlined,
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    QuestionCircleOutlined,
    LineChartOutlined,
} from "@ant-design/icons-vue";
import { useStore } from "vuex";
import ResetPwd from "@/components/ResetPwd";
import CodeSearch from "@/components/CodeSearch";
import { staticURL, publicURL } from "@/utils/config";

export default defineComponent({
    components: {
        UserOutlined,
        ExpandOutlined,
        CompressOutlined,
        MenuUnfoldOutlined,
        MenuFoldOutlined,
        ResetPwd,
        QuestionCircleOutlined,
        LineChartOutlined,
        CodeSearch,
    },
    setup() {
        const { getters, commit, dispatch } = useStore();
        const isFullScreen = ref(false);
        const codeSearchVisible = ref(false);
        const showResetPwd = ref(false);
        const sideMenuCollapsed = computed(() => getters["app/sider"]?.collapsed);
        const headerModules = computed(() => getters["app/modules"]);
        const userName = getters["user/userInfo"]?.username;
        const currentModule = computed(() => [getters["app/currentModule"]]);
        const changeCurrentModule = id => {
            commit("app/setCurrentModule", id);
        };

        const loginOut = () => {
            dispatch({
                type: "loginOut",
            });
        };

        const openPanel = path => {
            let _publicURL;
            if (process.env.NODE_ENV == "development") {
                _publicURL = "http://localhost:8080";
            } else {
                _publicURL = publicURL;
            }
            window.open(`${_publicURL}${path}`, "_blank");
        };

        const fullScreen = () => {
            screenfull.toggle();
        };

        screenfull.onchange(() => (isFullScreen.value = screenfull.isFullscreen));

        const changeSideMenuCollapsed = () =>
            commit("app/setSider", {
                collapsed: !sideMenuCollapsed.value,
            });

        const handlePreviewHelp = () => {
            window.open(`${staticURL}/about/help`);
        };

        return {
            showResetPwd,
            headerModules,
            codeSearchVisible,
            userName,
            currentModule,
            sideMenuCollapsed,
            changeCurrentModule,
            loginOut,
            openPanel,
            isFullScreen,
            fullScreen,
            changeSideMenuCollapsed,
            menuPermisson: getters["app/menuPermisson"],
            handlePreviewHelp,
        };
    },
});
</script>

<style lang="less" scoped>
:deep(.ant-dropdown-trigger) {
    display: flex;
    align-items: center;
}
.header-container {
    height: 55px;
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 21px;
    box-sizing: border-box;
    .header-left {
        display: flex;
        justify-content: center;
        align-items: center;
        .collapsed-icon {
            font-size: 16px;
            cursor: pointer;
        }
    }
    .header-right {
        display: flex;
        align-items: center;
        .username {
            display: inline-block;
            margin-left: 8px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 70px;
        }
    }
}
</style>
